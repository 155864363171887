<template>
  <div>
    <heade></heade>
    <div style="background: #fafafa">
      <div class="header">
        <div class="more">
          <div style="flex: 2; color: #fff">
            <p class="title">
              <span>{{ info.title }}</span>

              <el-tag
                type="warning"
                v-for="(t, i) in info.tags"
                :key="i"
                size="mini"
                style="margin-left: 10px"
                >{{ t }}</el-tag
              >
            </p>
            <ul style="display: flex; margin-top: 30px" class="list">
              <li>
                <p>章节</p>
                <p>{{ js[0] }}</p>
              </li>
              <li>
                <p>单元</p>
                <p>{{ js[1] }}</p>
              </li>
              <li>
                <p>实践关卡</p>
                <p>{{ js[2] }}</p>
              </li>
              <li>
                <p>经验值</p>
                <p>{{ js[3] }}</p>
              </li>
            </ul>
            <div>
              <div style="margin-top: 50px">
                <el-button type="primary" size="small" @click="addKcFun">{{
                  addJR ? "已加入课程" : "加入课程"
                }}</el-button>
                <div
                  style="
                    display: inline-block;
                    float: right;
                    margin-right: 30px;
                    margin-top: 9px;
                  "
                >
                  <span
                    style="
                      float: left;
                      margin-top: -2px;
                      margin-right: 10px;
                      font-size: 14px;
                    "
                    >{{ mark ? "已收藏" : "收藏" }}</span
                  >
                  <span
                    v-if="mark"
                    @click="markFun(false)"
                    style="cursor: pointer"
                    ><svg
                      t="1703078330836"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="7774"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M512 882.215385l-362.338462-362.338462c-90.584615-90.584615-90.584615-240.246154 0-334.769231 90.584615-90.584615 240.246154-90.584615 334.769231 0l27.569231 31.507693 27.569231-27.569231c90.584615-90.584615 240.246154-90.584615 334.769231 0 90.584615 90.584615 90.584615 240.246154 0 334.769231L512 882.215385z"
                        fill="#F30240"
                        p-id="7775"
                      ></path></svg></span>
                  <span v-else @click="markFun(true)" style="cursor: pointer"
                    ><svg
                      t="1703078668422"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="7982"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M511.7 897.1c-20.3 0-39.2-8-53.4-22.5L111.6 516.3C69.5 459 54.8 387.2 70 317.8c20-90.9 94.8-164.4 186.1-182.9C357.3 114.5 457.5 157 511.8 243c54.2-86 154.6-128.6 255.5-108.1 22.6 4.5 44.5 12.5 65.1 23.6 18.1 9.8 24.9 32.4 15.1 50.5-9.9 18.1-32.5 24.9-50.5 15.1-14.2-7.6-29.1-13-44.5-16.1-70.1-14.1-140 15.1-177.7 74.8-13.9 22-37.5 35.3-63 35.3-25.6 0-49.1-13.2-63-35.3C411 223.2 341.2 194 271 208.1c-62.8 12.7-114.3 63.3-128.1 125.8-10.8 49.2-0.9 97.8 27.7 136.9l10.1 12.3 331 339.4L854 469.3c27.6-37.7 37.4-86.3 26.6-135.5-1.2-5.8-2.8-11.4-4.7-17-6.6-19.5 3.9-40.7 23.5-47.2s40.7 4 47.2 23.5c2.8 8 5 16.3 6.9 24.8 15.3 69.4 0.5 141.2-40.4 197.1l-12.3 15.2-335.6 344.4c-14.1 14.5-33.1 22.5-53.5 22.5z"
                        fill="#C6CCDA"
                        p-id="7983"
                      ></path>
                      <path
                        d="M511.8 897.1c20.3 0 39.2-8 53.4-22.5l346.7-358.2c42.1-57.3 56.8-129.1 41.6-198.5-20-90.9-94.8-164.4-186.1-182.9-101.1-20.5-201.4 22-255.6 108-54.2-86-154.6-128.6-255.5-108.1-22.6 4.5-44.5 12.5-65.1 23.6-18.1 9.8-24.9 32.4-15.1 50.5 9.9 18.1 32.5 24.9 50.5 15.1 14.2-7.6 29.1-13 44.5-16.1 70.1-14.1 140 15.1 177.7 74.8 13.9 22 37.5 35.3 63 35.3 25.6 0 49.1-13.2 63-35.3 37.7-59.7 107.6-88.9 177.8-74.8 62.8 12.7 114.3 63.3 128.1 125.8 10.8 49.2 0.9 97.8-27.7 136.9L842.9 483l-331 339.4-342.3-353.1C142 431.6 132.1 383 143 333.8c1.2-5.8 2.8-11.4 4.7-17 6.6-19.5-3.9-40.7-23.5-47.2s-40.7 4-47.2 23.5c-2.8 8-5 16.3-6.9 24.8-15.3 69.4-0.5 141.2 40.4 197.1l12.3 15.2 335.6 344.5c14 14.4 33 22.4 53.4 22.4z"
                        fill="#C6CCDA"
                        p-id="7984"
                      ></path>
                      <path
                        d="M486 262.5l-135-90L196.5 195l-72 85.5-15 121.5 46.5 93 163.3 183L504 857.9 634.4 744l142.5-139.5L895.4 468l36-138-63-100.5-100.3-57H649.4L534 270l-30 12z"
                        fill="#C6CCDA"
                        p-id="7985"
                      ></path>
                      </svg></span>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>
        </div>
      </div>
      <div class="box" style="padding-left: 0">
        <div class="top">
          <el-breadcrumb separator="/" style="margin-top: 15px">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="toPath != ''" :to="{ path: '/kc' }"
              >课程</el-breadcrumb-item
            >
            <el-breadcrumb-item>课程详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="top-top">
            <h4>课程介绍</h4>
            <VueMarkdown
              v-if="info.intro != ''"
              :source="info.intro"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <el-empty description="暂无课程介绍" v-else></el-empty>
          </div>
          <div class="center">
            <el-input
              placeholder="请输入名称进行搜索"
              v-model="input3"
              class="input-with-select"
              size="small"
              style="position: absolute; right: 0; top: 0; width: 300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                size="small"
              ></el-button>
            </el-input>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="课程章节" name="first">
                <div class="zj">
                  <ul
                    class="zj-ul"
                    v-for="(item, index) in info.chapter"
                    :key="index"
                  >
                    <li class="zj-title">
                      <span
                        style="float: left; margin-top: 3px; margin-right: 10px"
                        >{{ item.title }}</span
                      >
                      <span
                        v-if="item.tags != ''"
                        style="
                          display: inline-block;
                          padding: 2px 10px;
                          background: #0084ff;
                          font-size: 12px;
                          color: #fff;
                          border-radius: 14px;
                        "
                        >{{ item.tags }}</span
                      >
                    </li>
                    <li>{{ item.intro }}</li>
                    <li
                      v-for="(t, i) in item.project"
                      :key="i"
                      class="hover-li"
                    >
                      <div v-if="t.type == '云实验'">
                        <!-- <a-icon
                                  type="sliders"
                                  style="color: rgb(0, 166, 255)"
                                /> -->
                        <i
                          class="el-icon-bangzhu"
                          style="color: rgb(0, 166, 255)"
                        ></i>
                        {{ i + 1 }}、【 {{ t.title }} 】

                        <span class="right-hover">
                          <span
                            v-if="t.url != ''"
                            @click="goTarget(t.url)"
                            style="
                              background: rgb(34, 171, 34);
                              color: #fff;
                              padding: 2px 10px;
                              border-radius: 5px;
                              margin-right: 20px;
                              cursor: pointer;
                            "
                            >去实验</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="t.intro"
                            placement="top-start"
                          >
                            <span
                              v-if="t.intro != ''"
                              style="
                                background: rgb(31, 146, 184);
                                color: #fff;
                                padding: 2px 10px;
                                border-radius: 5px;
                                cursor: pointer;
                              "
                              >查看详情</span
                            >
                          </el-tooltip>
                        </span>
                      </div>
                      <div v-if="t.type == '视频'">
                        <!-- <a-icon
                                  type="sliders"
                                  style="color: rgb(0, 166, 255)"
                                /> -->
                        <i
                          class="el-icon-video-play"
                          style="color: rgb(146, 3, 194)"
                        ></i>
                        {{ i + 1 }}、【 {{ t.title }} 】
                        <span class="right-hover">
                          <span
                            v-if="t.url != ''"
                            @click="goTarget(t.url)"
                            style="
                              background: rgb(34, 171, 34);
                              color: #fff;
                              padding: 2px 10px;
                              border-radius: 5px;
                              margin-right: 20px;
                              cursor: pointer;
                            "
                            >看视频</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="t.intro"
                            placement="top-start"
                          >
                            <span
                              v-if="t.intro != ''"
                              style="
                                background: rgb(31, 146, 184);
                                color: #fff;
                                padding: 2px 10px;
                                border-radius: 5px;
                                cursor: pointer;
                              "
                              >查看详情</span
                            >
                          </el-tooltip>
                        </span>
                      </div>
                      <div v-if="t.type == '课件'">
                        <!-- <a-icon
                                  type="sliders"
                                  style="color: rgb(0, 166, 255)"
                                /> -->
                        <i
                          class="el-icon-tickets"
                          style="color: rgb(248, 141, 0)"
                        ></i>
                        {{ i + 1 }}、【 {{ t.title }} 】
                        <span class="right-hover">
                          <span
                            v-if="t.url != ''"
                            @click="goTarget(t.url)"
                            style="
                              background: rgb(34, 171, 34);
                              color: #fff;
                              padding: 2px 10px;
                              border-radius: 5px;
                              margin-right: 20px;
                              cursor: pointer;
                            "
                            >看课件</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="t.intro"
                            placement="top-start"
                          >
                            <span
                              v-if="t.intro != ''"
                              style="
                                background: rgb(31, 146, 184);
                                color: #fff;
                                padding: 2px 10px;
                                border-radius: 5px;
                                cursor: pointer;
                              "
                              >查看详情</span
                            >
                          </el-tooltip>
                        </span>
                      </div>
                      <div v-if="t.type == '作业'">
                        <!-- <a-icon
                                  type="sliders"
                                  style="color: rgb(0, 166, 255)"
                                /> -->
                        <i
                          class="el-icon-reading"
                          style="color: rgb(19, 194, 3)"
                        ></i>
                        {{ i + 1 }}、【 {{ t.title }} 】
                        <span class="right-hover">
                          <span
                            v-if="t.url != ''"
                            @click="goTarget(t.url)"
                            style="
                              background: rgb(34, 171, 34);
                              color: #fff;
                              padding: 2px 10px;
                              border-radius: 5px;
                              margin-right: 20px;
                              cursor: pointer;
                            "
                            >做作业</span
                          >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="t.intro"
                            placement="top-start"
                          >
                            <span
                              v-if="t.intro != ''"
                              style="
                                background: rgb(31, 146, 184);
                                color: #fff;
                                padding: 2px 10px;
                                border-radius: 5px;
                                cursor: pointer;
                              "
                              >查看详情</span
                            >
                          </el-tooltip>
                        </span>
                      </div>
                    </li>
                    <!-- <li>1-1 d单链接操作</li>
                    <li>1-1 d单链接操作</li>
                    <li>1-1 d单链接操作</li>
                    <li>1-1 d单链接操作</li> -->
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="right">
          <div
            style="
              background: #fff;
              padding: 10px;
              margin-bottom: 10px;
              border-radius: 5px;
            "
          >
            <h4 style="margin-bottom: 20px">教师团队</h4>
            <ul class="js-ul" v-if="author.length > 0">
              <li v-for="(item, index) in author" :key="index">
                <span
                  style="
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: rgb(54 129 178);
                    text-align: center;
                    line-height: 80px;
                    border-radius: 80px;
                    color: #fff;
                    font-weight: bold;
                  "
                  >{{ item.name }}</span
                >
                <div
                  style="
                    margin-top: 5px;
                    color: #0488b7;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                  "
                >
                  {{ item.school }}
                </div>
              </li>
            </ul>
            <el-empty description="暂无教师团队" v-else></el-empty>
          </div>
          <div class="xuzhi" style="border-radius: 5px">
            <h4 style="margin-bottom: 20px">课程须知</h4>
            <VueMarkdown
              v-if="info.sintro != ''"
              :source="info.sintro"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <el-empty description="暂无课程须知" v-else></el-empty>
          </div>
          <div
            class="xuzhi"
            style="border-radius: 5px; margin-top: 10px"
            v-if="info.stags && info.stags.length != 0"
          >
            <h4 style="margin-bottom: 20px">技能标签</h4>
            <div id="tagsList">
              <a href="javascript:;" v-for="(t, i) in info.stags" :key="i">{{
                t
              }}</a>
            


            </div>
          </div>
        </div>
      </div>
    </div>

    <footerC></footerC>
  </div>
</template>


<script>
import heade from "@/components/heade.vue";
import footerC from "@/components/footerCommon.vue";
import VueMarkdown from "vue-markdown";
var radius = 120;
var dtr = Math.PI / 180;
var d = 300;
var mcList = [];
var active = false;
var lasta = 1;
var lastb = 1;
var distr = true;
var tspeed = 10;
var size = 250;
var sa ='';
var 	ca = ''
var 	sb = '';
var 	cb = '';
var 	sc = '';
var 	cc = '';
var per='';
var mouseX = 0;
var mouseY = 0;

var howElliptical = 1;

var aA = null;
var oDiv = null;
export default {
  name: "kcMore",
  components: { heade, footerC, VueMarkdown },
  data() {
    return {
      activeName: "first",
      input3: "",
      seqid: "",
      author: [],
      info: {},
      mark: false,
      js: [],
      toPath: "",
      addJR: false,
    };
  },
  mounted() {
    // setTimeout(()=>{})
    setTimeout(()=> {
      var i = 0;
      var oTag = null;

      document.getElementById('tagsList').style.display='block'
      oDiv = document.getElementById("tagsList");
      console.log(oDiv);

      aA = oDiv.getElementsByTagName("a");

      for (i = 0; i < aA.length; i++) {
        oTag = {};

        oTag.offsetWidth = aA[i].offsetWidth;
        oTag.offsetHeight = aA[i].offsetHeight;

        mcList.push(oTag);
      }
debugger;
      this.sineCosine(0, 0, 0);

      this.positionAll();

      oDiv.onmouseover = function () {
        active = true;
      };

      oDiv.onmouseout = function () {
        active = false;
      };

      oDiv.onmousemove = function (ev) {
        var oEvent = window.event || ev;

        mouseX = oEvent.clientX - (oDiv.offsetLeft + oDiv.offsetWidth / 2);
        mouseY = oEvent.clientY - (oDiv.offsetTop + oDiv.offsetHeight / 2);

        mouseX /= 5;
        mouseY /= 5;
      };

      setInterval(()=>{
        var a;
      var b;

      if (active) {
        a = (-Math.min(Math.max(-mouseY, -size), size) / radius) * tspeed;
        b = (Math.min(Math.max(-mouseX, -size), size) / radius) * tspeed;
      } else {
        a = lasta * 0.98;
        b = lastb * 0.98;
      }

      lasta = a;
      lastb = b;

      if (Math.abs(a) <= 0.01 && Math.abs(b) <= 0.01) {
        return;
      }

      var c = 0;
      debugger
      this.sineCosine(a, b, c);
      for (var j = 0; j < mcList.length; j++) {
        var rx1 = mcList[j].cx;
        var ry1 = mcList[j].cy * ca + mcList[j].cz * -sa;
        var rz1 = mcList[j].cy * sa + mcList[j].cz * ca;

        var rx2 = rx1 * cb + rz1 * sb;
        var ry2 = ry1;
        var rz2 = rx1 * -sb + rz1 * cb;

        var rx3 = rx2 * cc + ry2 * -sc;
        var ry3 = rx2 * sc + ry2 * cc;
        var rz3 = rz2;

        mcList[j].cx = rx3;
        mcList[j].cy = ry3;
        mcList[j].cz = rz3;

        per = d / (d + rz3);

        mcList[j].x = howElliptical * rx3 * per - howElliptical * 2;
        mcList[j].y = ry3 * per;
        mcList[j].scale = per;
        mcList[j].alpha = per;

        mcList[j].alpha = (mcList[j].alpha - 0.6) * (10 / 6);
      }

      this.doPosition();
      this.depthSort();
      }, 30);
    }, 500);

    // window.onload=function ()

    this.seqid = this.$route.query.seqid;
    this.init();
    this.jishuFun();
    if (window.localStorage.getItem("morePath_anjing") == "首页") {
      this.toPath = "";
    } else {
      this.toPath = "123";
    }
    if (
      window.location.href.indexOf("token=") != -1 ||
      (window.localStorage.getItem("anjing_name_token") &&
        window.localStorage.getItem("anjing_name_token") != "")
    ) {
      let data = {
        op: "getuserinfo",
        token: window.localStorage.getItem("anjing_name_token"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // window.localStorage.setItem("anjing_name_uid",response.data.data.uid);
            console.log(response.data.data.uid);
            _this.getsTATEfuN(response.data.data.uid);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  methods: {
    update() {
      var a;
      var b;

      if (active) {
        a = (-Math.min(Math.max(-mouseY, -size), size) / radius) * tspeed;
        b = (Math.min(Math.max(-mouseX, -size), size) / radius) * tspeed;
      } else {
        a = lasta * 0.98;
        b = lastb * 0.98;
      }

      lasta = a;
      lastb = b;

      if (Math.abs(a) <= 0.01 && Math.abs(b) <= 0.01) {
        return;
      }

      var c = 0;
      debugger
      this.sineCosine(a, b, c);
      for (var j = 0; j < mcList.length; j++) {
        var rx1 = mcList[j].cx;
        var ry1 = mcList[j].cy * ca + mcList[j].cz * -sa;
        var rz1 = mcList[j].cy * sa + mcList[j].cz * ca;

        var rx2 = rx1 * cb + rz1 * sb;
        var ry2 = ry1;
        var rz2 = rx1 * -sb + rz1 * cb;

        var rx3 = rx2 * cc + ry2 * -sc;
        var ry3 = rx2 * sc + ry2 * cc;
        var rz3 = rz2;

        mcList[j].cx = rx3;
        mcList[j].cy = ry3;
        mcList[j].cz = rz3;

        per = d / (d + rz3);

        mcList[j].x = howElliptical * rx3 * per - howElliptical * 2;
        mcList[j].y = ry3 * per;
        mcList[j].scale = per;
        mcList[j].alpha = per;

        mcList[j].alpha = (mcList[j].alpha - 0.6) * (10 / 6);
      }

      this.doPosition();
      this.depthSort();
    },

    depthSort() {
      var i = 0;
      var aTmp = [];

      for (i = 0; i < aA.length; i++) {
        aTmp.push(aA[i]);
      }

      aTmp.sort(function (vItem1, vItem2) {
        if (vItem1.cz > vItem2.cz) {
          return -1;
        } else if (vItem1.cz < vItem2.cz) {
          return 1;
        } else {
          return 0;
        }
      });

      for (i = 0; i < aTmp.length; i++) {
        aTmp[i].style.zIndex = i;
      }
    },

    positionAll() {
      var phi = 0;
      var theta = 0;
      var max = mcList.length;
      var i = 0;

      var aTmp = [];
      var oFragment = document.createDocumentFragment();

      for (i = 0; i < aA.length; i++) {
        aTmp.push(aA[i]);
      }

      aTmp.sort(function () {
        return Math.random() < 0.5 ? 1 : -1;
      });

      for (i = 0; i < aTmp.length; i++) {
        oFragment.appendChild(aTmp[i]);
      }

      oDiv.appendChild(oFragment);

      for (var i = 1; i < max + 1; i++) {
        if (distr) {
          phi = Math.acos(-1 + (2 * i - 1) / max);
          theta = Math.sqrt(max * Math.PI) * phi;
        } else {
          phi = Math.random() * Math.PI;
          theta = Math.random() * (2 * Math.PI);
        }
        //����任
        mcList[i - 1].cx = radius * Math.cos(theta) * Math.sin(phi);
        mcList[i - 1].cy = radius * Math.sin(theta) * Math.sin(phi);
        mcList[i - 1].cz = radius * Math.cos(phi);

        aA[i - 1].style.left =
          mcList[i - 1].cx +
          oDiv.offsetWidth / 2 -
          mcList[i - 1].offsetWidth / 2 +
          "px";
        aA[i - 1].style.top =
          mcList[i - 1].cy +
          oDiv.offsetHeight / 2 -
          mcList[i - 1].offsetHeight / 2 +
          "px";
      }
    },

    doPosition() {
      var l = oDiv.offsetWidth / 2;
      var t = oDiv.offsetHeight / 2;
      for (var i = 0; i < mcList.length; i++) {
        aA[i].style.left = mcList[i].cx + l - mcList[i].offsetWidth / 2 + "px";
        aA[i].style.top = mcList[i].cy + t - mcList[i].offsetHeight / 2 + "px";

        aA[i].style.fontSize = Math.ceil((12 * mcList[i].scale) / 2) + 8 + "px";

        aA[i].style.filter = "alpha(opacity=" + 100 * mcList[i].alpha + ")";
        aA[i].style.opacity = mcList[i].alpha;
      }
    },

    sineCosine(a, b, c) {
      sa = Math.sin(a * dtr);
      ca = Math.cos(a * dtr);
      sb = Math.sin(b * dtr);
      cb = Math.cos(b * dtr);
      sc = Math.sin(c * dtr);
      cc = Math.cos(c * dtr);
    },

    handleClick() {},
    init() {
      let data = {
        op: "detail",
        seqid: this.seqid,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.author = response.data.data.author;
            // _this.newsBookAry = response.data.data;
            // console.log("_this.newsBookAry", _this.newsBookAry);
            // _this.newsBookCount = response.data.recordcount;
            _this.info = response.data.data;
          }
         
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getsTATEfuN(uid) {
      let data = {
        op: "getstate",
        courseid: this.info._id,
        uid: uid,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // 动态
            _this.addJR = JSON.parse(response.data.data.join);
            _this.mark = JSON.parse(response.data.data.mark);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    markInit(op) {
      let data = {
        op: op,
        uid: window.localStorage.getItem("anjing_name_uid"),
        courseid: this.info._id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            op == "mark"
              ? this.$message.success("收藏成功!")
              : this.$message.success("取消收藏!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    markFun(val) {
      if (
        window.localStorage.getItem("anjing_name_ebook") &&
        window.localStorage.getItem("anjing_name_ebook") != ""
      ) {
        this.mark = val;
        let op = "";
        val ? (op = "mark") : (op = "unmark");
        this.markInit(op);
      } else {
        this.$confirm("登录后可收藏, 是否登录?", "登录提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loginFun();
          })
          .catch(() => {});
      }
      // val?this.$message.success('收藏成功!'):this.$message.success('取消收藏!')
    },
    goTarget(url) {
      window.open(url);
    },
    // 课程计数信息
    jishuFun() {
      let data = {
        op: "info",
        seqid: this.seqid,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.js = response.data.data;
            // op=='mark'?this.$message.success('收藏成功!'):this.$message.success('取消收藏!')
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addKcFun() {
      if (
        window.localStorage.getItem("anjing_name_ebook") &&
        window.localStorage.getItem("anjing_name_ebook") != ""
      ) {
        if (this.addJR) {
          return;
        }
        let data = {
          op: "joincourse",
          uid: window.localStorage.getItem("anjing_name_uid"),
          courseid: this.info._id,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/course.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              // _this.js=response.data.data;
              _this.addJR = true;
              _this.$message.success("加入成功!");
              // _this.$message.success
              // op=='mark'?this.$message.success('收藏成功!'):this.$message.success('取消收藏!')
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$confirm("登录后可加入课程, 是否登录?", "登录提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loginFun();
          })
          .catch(() => {});
      }
    },
    loginFun() {
      debugger;
      let data = {
        op: "login",
        // more:this.seqid
        type: "more",
        key: "seqid",
        value: this.seqid,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
body {
  background: #fafafa;
}
.more {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.header {
  background: url(../images/topb.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  height: 245px;
  padding-top: 55px;
  border-bottom: 1px solid #ccc;
}
.heade{
  box-shadow:none !important;
}
.title {
  font-size: 30px;
  font-weight: bold;
}
.list li {
  padding: 2px 15px 2px 0;
}
.list li p {
  text-align: center;
}
.box {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
.top {
  flex: 2;
  text-align: left;
}
.top-top {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}
p {
  font-weight: 400;
}
.heade {
  box-shadow: none;
  margin-bottom: 0;
}
.top-top p {
  font-size: 15px;
}
.top-top h4 {
  margin-bottom: 20px;
}
.center {
  margin-top: 20px;
  position: relative;
}
.right {
  flex: 1;
  /* margin-top:-44px; */
  /* padding: 10px;
  background: #fff; */
  border-radius: 5px;
  margin-left: 10px;
  margin-top: -229px;
}
.zj-ul {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
}
.zj-ul li {
  padding: 10px;
  overflow: auto;
}
.zj-title {
  font-weight: bold;
  /* margin-bottom: 10px; */
  font-size: 18px;
}
.js-ul {
  overflow: auto;
  font-size: 14px;
}
.js-ul li {
  width: 33%;
  float: left;
  /* margin-right:10px; */
  text-align: center;
  margin-bottom: 10px;
}
.xuzhi {
  background: #fff;
  padding: 10px;
}
.hover-li:hover {
  background: rgb(217, 235, 249);
}
.hover-li .right-hover {
  display: none;
  float: right;
  font-size: 14px;
}
.hover-li:hover .right-hover {
  display: block;
}

a {
  color: #666;
  text-decoration: none;
  cursor: pointer;
}
#tagsList {
  display: none;

  position: relative;

  /* width: 450px; */

  min-height: 200px;

  /* margin: 150px auto 0; */
}

#tagsList a {
  position: absolute;

  top: 0px;

  left: 0px;

  font-family: Microsoft YaHei;

  color: #4e0404;

  font-weight: bold;

  text-decoration: none;

  padding: 3px 6px;
}

#tagsList a:hover {
  color: #ff0000;

  letter-spacing: 2px;
}
</style>